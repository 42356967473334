<template>
  <div class="all-container">
    <div class="all-container-head">
      <div>当前用户：</div>
      <div class="login-email">{{ userInfo.email }}</div>
      <div class="login-out" @click="loginOut">退出登录</div>
    </div>
    <div class="all-container-body">
      <nav class="app-nav">
        <div>
          <router-link to="/">后台</router-link>
        </div>
        <div>
          <router-link to="/shadowsetting">影追凶设置</router-link>
        </div>
        <div>
          <router-link to="/leftsetting">浮生记设置</router-link>
        </div>
        <div>
          <router-link to="/settings">设置</router-link>
        </div>
      </nav>
      <!-- 在此处添加 <router-view> 来展示当前路由对应的组件 -->
      <router-view class="app-content"></router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LayoutWithNav',
  data () {
    return {
      userInfo: {}
    }
  },
  mounted () {
    let userStr = localStorage.getItem('userInfo')
    if (!userStr) {
      this.$router.push('/login')
    }
    this.userInfo = JSON.parse(userStr)
    if (!this.userInfo || !this.userInfo.email) {
      console.log('没有登录')
    }
  },
  methods: {
    loginOut () {
      localStorage.setItem('token', '')
      localStorage.setItem('userInfo', '')
      this.$router.push('/login')
    },
  }
}
</script>

<style scoped lang="less">
.all-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 650px;
}

.all-container-head {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .login-email {
    color: blue;
  }

  .login-out {
    margin-left: 20px;
    cursor: pointer;
    user-select: none;
    border: 1px solid #000;
    padding: 2px;
  }
}

.all-container-body {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.app-nav {
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid;
  align-items: center;
  padding: 10px;
  height: 600px;
  overflow-y: auto;
}

.app-nav>div {
  width: 80%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #000;
  margin-top: 2px;
}

.app-nav>div>a {
  text-decoration: none;
}

.app-content {
  width: 80%;
}
</style>
