<template>
  <div>
    <h1>浮生记设置</h1>
    <div class="main">
      <template v-if="false">
        <div class="left">
          <div class="menu" v-for="(menu, index) in menuList" :key="index" :class="{ activeMenu: activeMenu == menu.id }"
            @click="changeMenu(menu)">
            {{ menu.name }}
          </div>
        </div>
        <div class="right">
          <div class="setting" v-if="activeMenu == 1">
            <div>
              <el-input style="width: 500px;" type="textarea" :rows="10" :resize="'none'" placeholder="请输入默认回复"
                v-model="content">
              </el-input>
              <div style="margin-top: 20px;" class="menu" @click="saveAutoReply()">保存</div>
            </div>
          </div>


          <div class="setting" v-if="activeMenu == 2">
            <div class="item">
              <el-input type="input" placeholder="输入新增违禁词" v-model="newWord">
              </el-input>
              <div style="margin-left: 10px; margin-bottom: 0px;" @click="addWord" class="menu">添加</div>
            </div>

            <ul class="forbidden-list">
              <li v-for="(word, index) in forbiddenWords" :key="index">
                {{ word['word'] }}

                <div @click="deleteWord(word['id'])" class="menu">删除</div>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="table-content">
          <el-table :data="configs" border style="width: 100%">
            <el-table-column fixed prop="group" label="分组" width="150">
            </el-table-column>
            <el-table-column prop="code" label="编码" width="120">
            </el-table-column>
            <el-table-column prop="name" label="名称" width="120">
            </el-table-column>
            <el-table-column prop="content" label="值">
              <template slot-scope="scope">
                <span style="color: red">{{ scope.row.content }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="desc" label="描述" width="300">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-popover placement="right" width="400" trigger="click">
                  <div class="popover-continer">
                    <el-input v-model="formContent.content" placeholder="请输入值"></el-input>
                    <el-input v-model="formContent.desc" placeholder="该项的描述，本项非必填"></el-input>
                    <el-button @click="updateConfig">保存</el-button>
                  </div>

                  <el-button slot="reference" @click="editrow(scope.row)" type="text" size="small">编辑</el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getAutoReply, saveAutoReply, getForbiddenWord, saveForbiddenWord, deleteForbiddenWord, getConfigs, addKeyWordConfig, updateConfig } from '@/api/shadow'
export default {
  name: 'LeftSettings',
  data () {
    return {
      menuList: [
        { name: '默认回复', id: 1 },
        { name: '违禁词', id: 2 },

      ],
      activeMenu: 1,
      content: '',
      newWord: "",
      forbiddenWords: [],
      configs: [],
      formContent: {
        id: "",
        content: "",
        desc: ""
      }
    }
  },
  created () {
    this.changeMenu(this.menuList[1])
  },
  mounted () {
    this.getConfigList()
  },
  methods: {
    changeMenu (menu) {
      this.activeMenu = menu.id
      if (menu.id == 1) {
        this.getAutoReply()
      }
      if (menu.id == 2) {
        this.getForbiddenWord()
      }
    },
    getForbiddenWord () {
      getForbiddenWord().then(res => {
        this.forbiddenWords = res
      })
    },
    getAutoReply () {
      getAutoReply().then(res => {
        this.content = res.data.content
      })
    },
    saveAutoReply () {
      saveAutoReply({ content: this.content }).then(res => {
        if (res.error_code == 200) {
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          });
        } else {
          this.$notify.error({
            title: '错误',
            message: '保存失败，请重试~'
          });
        }
      })
    },

    addWord () {
      if (this.newWord.trim()) {
        saveForbiddenWord({ word: this.newWord.trim() }).then(res => {
          if (res.error_code == 200) {
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success'
            });
            this.getForbiddenWord()
          } else {
            this.$notify.error({
              title: '错误',
              message: '保存失败，请重试~'
            });
          }
        })
        this.newWord = "";
      }
    },
    deleteWord (id) {
      deleteForbiddenWord(id).then(res => {
        console.log(res)
        this.getForbiddenWord()
      })
    },
    getConfigList () {
      getConfigs('left').then(res => {
        this.configs = res
      })
    },
    updateConfig () {
      console.log(addKeyWordConfig)
      updateConfig(this.formContent).then(res => {
        if (res && res.error_code == 200) {
          this.getConfigList()
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          });
        } else {
          this.$notify.error({
            title: '错误',
            message: '保存失败，请重试~'
          });
        }
      })
    },
    editrow (row) {
      this.formContent.id = row.id
      this.formContent.content = row.content
      this.formContent.desc = row.desc
    }
  }
}
</script>

<style>
.main {
  display: flex;
}

.menu {
  width: 80px;
  height: 30px;
  border: 1px solid black;
  margin-bottom: 10px;
  line-height: 30px;
  cursor: pointer;
}

.activeMenu {
  background: #ccc;
}

.right {
  margin-left: 20px;
}

.item {
  display: flex;
  align-items: center;
}

.forbidden-list {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  list-style-type: none;
}

.forbidden-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.table-content {
  width: 100%;
}

.popover-continer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 150px;
  justify-content: space-around;
}
</style>
