import axios from 'axios'

// 创建一个 Axios 实例
const instance = axios.create({
  baseURL: 'http://chat.linlinju.com:60200', // 替换为你的 API 地址
  // baseURL: 'http://localhost:5000',
  timeout: 10000, // 设置请求超时时间（单位：毫秒）
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前可以做一些操作，例如添加请求头
    let token = localStorage.getItem('token')
    config.headers['Authorization'] = 'JWT ' + token; // 如果需要添加认证信息
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 添加响应拦截器
/* eslint-disable */
instance.interceptors.response.use(
  (response) => {
    // 在这里可以对响应数据进行处理
    return response.data;
  },
  (error) => {
    if (error.response.status == '401') {
      localStorage.setItem('token', '');
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
