import request from '@/utils/request'

export function getAutoReply () {
  return request({
    url: '/api/v2/shadow/autoReply',
    method: 'get'
  })
}

export function saveAutoReply (data) {
    return request({
      url: '/api/v2/shadow/autoReply',
      data,
      method: 'put'
    })
}

export function getForbiddenWord () {
    return request({
        url: '/api/v2/shadow/forbiddenWord',
        method: 'get'
    })
}

export function saveForbiddenWord (data) {
    return request({
      url: '/api/v2/shadow/forbiddenWord',
      data,
      method: 'post'
    })
}


export function deleteForbiddenWord (id) {
    return request({
      url: '/api/v2/shadow/forbiddenWord/' + id,
      method: 'delete'
    })
}

export function updateConfig (data) {
  return request({
    url: '/api/v2/config',
    data,
    method: 'put'
  })
}

export function addKeyWordConfig (data) {
  return request({
    url: '/api/v2/config',
    data,
    method: 'post'
  })
}

export function getConfigs (belong) {
  return request({
    url: `/api/v2/config?belong=${belong}`,
    method: 'get'
  })
}
