import request from '@/utils/request'

// 获取全部记录
export function getAllUsers () {
  return request({
    url: '/api/v2/getAllUsers'
  })
}

// 设置语言模型
export function setLanguageModel (data) {
  return request({
    url: '/api/v2/setLanguageModel',
    data,
    method: 'post'
  })
}
