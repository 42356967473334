import request from '@/utils/request'

// 获取角色列表
export function getAiList () {
  return request({
    url: '/api/v2/ai',
    method: 'get'
  })
}
// 删除角色
export function deleteAi (code) {
  return request({
    url: `/api/v2/ai/${code}`,
    method: 'delete'
  })
}
// 修改角色
export function updateAi (data) {
  return request({
    url: `/api/v2/ai/${data.code}`,
    data,
    method: 'put'
  })
}
// 获取角色详情
export function getAiDetail (code) {
  return request({
    url: `/api/v2/ai/${code}`,
    method: 'get'
  })
}
// 新增角色
export function addAi (data) {
  return request({
    url: '/api/v2/ai',
    data,
    method: 'post'
  })
}
