<template>
  <div class="content">
    <div>
      <el-table :data="users" border style="width: 100%">
        <el-table-column fixed prop="id" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="email" label="邮箱">
        </el-table-column>
        <el-table-column prop="languageModel" label="语言模型">
          <template slot-scope="scope">
            {{ getLanguageName(scope.row.languageModel) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="修改语言模型" :visible.sync="editingInfo.editing" width="36%">
      <el-form :model="editingInfo">
        <el-form-item label="">
          <el-select v-model="editingInfo.languageModel">
            <el-option v-for="item in languageModels" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="doEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAllUsers, setLanguageModel } from '@/api/user'
export default {
  name: 'AppSettings',
  data () {
    return {
      users: [],
      editingInfo: {
        languageModel: 'openai',
        editing: false,
        email: ''
      },
      languageModels: [{
        value: 'openai',
        label: 'openai'
      }, {
        value: 'baidu',
        label: '文心一言'
      }, {
        value: 'tongyi',
        label: '通义千问'
      }]
    }
  },
  mounted () {
    this.getAllUsers()
  },
  methods: {
    getLanguageName (code) {
      if (code == 'openai') {
        return 'openai'
      } else if (code == 'baidu') {
        return '文心一言'
      } else if (code == 'tongyi') {
        return '通义千问'
      }
    },
    getAllUsers () {
      getAllUsers().then((res) => {
        this.users = res.data
      })
    },
    edit (row) {
      this.editingInfo.editing = true
      this.editingInfo.email = row.email
      this.editingInfo.languageModel = row.languageModel
    },
    doEdit () {
      setLanguageModel({ 'email': this.editingInfo.email, 'languageModel': this.editingInfo.languageModel }).then((res) => {
        if (res.error_code == 200) {
          this.getAllUsers()
          this.editingInfo.editing = false
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          });
        } else {
          this.editingInfo.editing = false
          this.$notify.error({
            title: '错误',
            message: '保存失败，请重试~'
          });
        }
      })
    },
    cancel () {
      this.editingInfo.editing = false
      this.editingInfo.email = ''
      this.editingInfo.languageModel = ''
    }
  }
}
</script>

<style scoped lang="less">
.content {
  position: relative;

  .pop-model {
    width: 35%;
    height: 300px;
    background-color: aqua;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 300px;
  }
}
</style>
