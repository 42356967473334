<template>
  <div class="container">
    <!-- <div class="head-bar">
      <div>当前用户：</div>
      <div class="login-email">{{ userInfo.email }}</div>
      <div class="login-out" @click="loginOut">退出登录</div>
    </div> -->
    <el-form :model="aiForm" :rules="airules" ref="aiForm" class="box">
      <div class="role">
        <div v-for="ai in ais" :key="ai.code" @click="clickAI(ai)">{{ ai.name }}</div>
        <div @click="clickAdd">添加</div>
      </div>
      <div class="content">
        <div class="content1">
          <template v-if="isAdd || isEdit">
            <div class="cdiv">
              <el-form-item label="" prop="name">
                <el-input type="text" placeholder="角色名称" v-model="aiForm.name"></el-input>
              </el-form-item>
            </div>
            <div class="cdiv">聊天记录</div>
            <div class="cdiv">删除角色</div>
            <div class="cdiv">总对话次数</div>
          </template>
          <template v-else>
            <div class="cdiv">{{ this.aiForm.name }}</div>
            <div class="cdiv">聊天记录</div>
            <div class="cdiv" @click="deleteAI">删除角色</div>
            <div class="cdiv">总对话次数</div>
          </template>
        </div>
        <div class="content2">
          <div class="content2-upper">
            <template v-if="isAdd || isEdit">
              <div class="cbdiv cbdivi">
                <el-form-item label="" prop="summary">
                  <el-input type="textarea" :rows="10" :resize="'none'" placeholder="请输入角色概要" v-model="aiForm.summary">
                  </el-input>
                </el-form-item>
              </div>
              <div class="cbdiv cbdivi">
                <el-form-item label="" prop="personality">
                  <el-input type="textarea" :rows="10" :resize="'none'" placeholder="请输入角色性格"
                    v-model="aiForm.personality">
                  </el-input>
                </el-form-item>
              </div>
            </template>
            <template v-else>
              <div class="cbdiv">{{ this.aiForm.summary }}</div>
              <div class="cbdiv">{{ this.aiForm.personality }}</div>
            </template>
          </div>
          <div class="content2-below">
            <template v-if="isAdd || isEdit">
              <div class="cbdiv cbdivi">
                <el-form-item label="" prop="memory">
                  <el-input type="textarea" :rows="10" :resize="'none'" placeholder="请输入角色记忆" v-model="aiForm.memory">
                  </el-input>
                </el-form-item>
              </div>
              <div class="cbdiv cbdivi">
                <el-form-item label="" prop="eg">
                  <el-input type="textarea" :rows="10" :resize="'none'" placeholder="请输入聊天示例" v-model="aiForm.eg">
                  </el-input>
                </el-form-item>
              </div>
            </template>
            <template v-else>
              <div class="cbdiv">{{ this.aiForm.memory }}</div>
              <div class="cbdiv">{{ this.aiForm.eg }}</div>
            </template>
          </div>
        </div>
      </div>
      <div class="save-btn go-btn" @click="goChatPage()">
        前往聊天页面
      </div>
      <div v-if="this.isEdit || this.isAdd" class="save-btn" @click="submitForm('aiForm')">
        保存
      </div>
      <div class="save-btn save-cancel" @click="clickBtn">
        {{ isAdd || isEdit ? "取消" : "编辑" }}
      </div>
    </el-form>
  </div>
</template>

<script>
import { getAiList, getAiDetail, addAi, updateAi, deleteAi } from '@/api/backpage'
export default {
  data () {
    return {
      ais: [],
      userInfo: {},
      aiForm: {
        name: '',
        summary: '',
        personality: '',
        memory: '',
        eg: ''
      },
      isAdd: false,
      isEdit: false,
      airules: {
        name: [
          { required: true, message: '请输入AI角色的名称', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
        summary: [
          { required: true, message: '请输入AI角色的概要', trigger: 'blur' },
          { max: 2000, message: '长度不能超过2000 个字符', trigger: 'blur' }
        ],
        personality: [
          { required: true, message: '请输入AI角色的性格', trigger: 'blur' },
          { max: 2000, message: '长度不能超过2000 个字符', trigger: 'blur' }
        ],
        memory: [
          { required: true, message: '请输入AI角色的记忆', trigger: 'blur' },
          { max: 2000, message: '长度不能超过2000 个字符', trigger: 'blur' }
        ],
        eg: [
          { required: true, message: '请输入AI角色聊天示例', trigger: 'blur' },
          { max: 2000, message: '长度不能超过2000 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    let userStr = localStorage.getItem('userInfo')
    if (!userStr) {
      this.$router.push('/login')
    }
    // eslint-disable-next-line no-debugger
    this.userInfo = JSON.parse(userStr)
    if (!this.userInfo || !this.userInfo.email) {
      this.$router.push('/login')
    }

    this.getAis()
  },
  methods: {
    loginOut () {
      localStorage.setItem('token', '')
      localStorage.setItem('userInfo', '')
      this.$router.push('/login')
    },
    getAis () {
      getAiList().then((res) => {
        if (res) {
          this.ais = res.data
        }
      })
    },
    getAi (code) {
      getAiDetail(code).then((res) => {
        this.aiForm = res.data
        console.log(res)
      })
    },
    clickAI (ai) {
      this.isAdd = false
      this.isEdit = false
      this.getAi(ai.code)
    },
    clickAdd () {
      this.aiForm = {}

      this.isAdd = true
    },
    clickBtn () {
      if (this.isAdd) {
        // 取消添加
        this.reset()
        this.isAdd = false
      } else if (this.isEdit) {
        // 取消编辑
        this.reset()
        this.isEdit = false
      } else {
        if (this.aiForm.code) {
          this.isEdit = true
        } else {
          this.$notify({
            title: '警告',
            message: '请先选择需要编辑的AI角色',
            type: 'warning'
          });
        }
      }
    },
    reset () {
      this.getAis()
      if (this.aiForm && this.aiForm.code) {
        this.getAi(this.aiForm.code)
      }
    },
    goChatPage () {
      this.$router.push('/chatpage')
    },
    deleteAI () {
      this.$confirm('您确定要删除吗?', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (!this.aiForm.code) {
            this.$notify({
              title: '错误',
              message: '请选择要删除的角色',
              type: 'error',
            })
          } else {
            deleteAi(this.aiForm.code).then(res => {
              if (res && res.msg == 'success') {
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                })
                this.aiForm = {}
                this.getAis()
              }
              console.log(res)
            })
          }
        })
        .catch(() => {

        })
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isAdd) {
            addAi(this.aiForm).then(res => {
              this.$notify({
                title: '成功',
                message: '保存成功',
                type: 'success'
              });
              console.log(res)
            }).catch(e => {
              console.log(e)
            }).finally(() => {
              this.isAdd = false
              this.reset()
            })
          } else if (this.isEdit) {
            updateAi(this.aiForm).then(res => {
              this.$notify({
                title: '成功',
                message: '保存成功',
                type: 'success'
              });
              console.log(res)
            }).catch(e => {
              console.log(e)
            }).finally(() => {
              this.isEdit = false
              this.reset()
            })
          }
        }
      })
    }
  }
}
</script>


<style scoped lang="less">
.container {

  .head-bar {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .login-email {
      color: blue;
    }

    .login-out {
      margin-left: 20px;
      cursor: pointer;
      user-select: none;
      border: 1px solid #000;
      padding: 2px;
    }
  }

  .box {
    border: 3px solid #000;
    display: flex;
    height: 600px;
    position: relative;

    .role {
      flex: 1;
      border-right: 3px solid #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
      height: 98%;

      div {
        width: 80px;
        height: 30px;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        cursor: pointer;
      }
    }

    .content {
      flex: 9;
      display: flex;

      .content1 {
        flex: 2;
        border-right: 3px solid #000;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cdiv {
          width: 80%;
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px solid #000;
          margin-top: 25px;
          cursor: pointer;
        }
      }

      .content2 {
        flex: 8;

        .content2-upper {
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 300px;

          .cbdiv {
            width: 90%;
            height: 250px;
            padding: 0 10px;
            border: 1px solid #000;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow-y: auto;

            .el-form-item {
              width: 100%;
              margin-bottom: 0px;
            }
          }

          .cbdivi {
            align-items: center;
          }
        }

        .content2-below {
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 300px;

          .cbdiv {
            width: 90%;
            height: 250px;
            padding: 0 10px;
            border: 1px solid #000;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow-y: auto;

            .el-form-item {
              width: 100%;
              margin-bottom: 0px;
            }
          }

          .cbdivi {
            align-items: center;
          }
        }
      }
    }

    .save-btn {
      position: absolute;
      top: 620px;
      left: 80%;
      width: 100px;
      height: 50px;
      line-height: 50px;
      border: 2px solid #000;
      text-align: center;
      cursor: pointer;
    }

    .save-cancel {
      left: 90%;
    }

    .go-btn {
      left: 70%;
    }
  }
}
</style>
