import { render, staticRenderFns } from "./backPage.vue?vue&type=template&id=6b1cabca&scoped=true&"
import script from "./backPage.vue?vue&type=script&lang=js&"
export * from "./backPage.vue?vue&type=script&lang=js&"
import style0 from "./backPage.vue?vue&type=style&index=0&id=6b1cabca&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1cabca",
  null
  
)

export default component.exports