// src/router.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import LayoutWithNav from '@/layout/LayoutWithNav'
import backPage from '@/views/backPage'
import AppSettings from '@/views/settings'
import ShadowSettings from '@/views/shadowSettings'
import LeftSettings from '@/views/leftSettings'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: LayoutWithNav,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        component: backPage
      },
    ],
  },
  {
    path: '/shadowsetting',
    component: LayoutWithNav,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        component: ShadowSettings
      },
    ],
  },
  {
    path: '/leftsetting',
    component: LayoutWithNav,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        component: LeftSettings
      },
    ],
  },
  {
    path: '/settings',
    component: LayoutWithNav,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        component: AppSettings
      },
    ],
  },
  {
    path: '/chatpage',
    name: 'chatpage',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/views/chatPage.vue'),
  },
  {
    path: '/chatselect',
    name: 'chatselect',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/views/chatSelect.vue'),
  },
  {
    path: '/chatdo',
    name: 'chatdo',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/views/chatDo.vue'),
  },
  {
    path: '/chatVoice',
    name: 'chatVoice',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/views/chatVoice.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/logIn.vue'),
  },
];

const router = new VueRouter({
  mode: 'history', // 使用HTML5 history模式，去掉URL中的#
  base: process.env.BASE_URL,
  routes,
});

/* eslint-disable */
// 路由拦截器
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.meta.requireAuth) {
    // 如果该路由需要登录才能访问
    if (token) {
      // 如果已经登录
      next();
    } else {
      // 否则跳转到登录页
      next({
        path: '/login',
        query: { redirect: to.fullPath } // 将跳转的路由 path 作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    // 不需要登录就能访问的页面，直接放行
    next();
  }
});

export default router;
