import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'; // 导入路由配置文件

Vue.use(ElementUI)


Vue.config.productionTip = false

new Vue({
  router, // 注入路由
  render: h => h(App),
}).$mount('#app')
